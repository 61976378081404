



































import { Vue, Component } from 'vue-property-decorator';
import DashboardApi from '@/services/dashboard';
import store from '@/store';
import { DashboardConfiguration } from '@/models/dashboard';

@Component
export default class General extends Vue {
  dashboardApi = new DashboardApi();

  dashboardId: number | null = null;

  dashboard: DashboardConfiguration | null = null;

  isModalDeleteOpen = false;

  created(): void {
    store.dispatch('setLoading', true);
    this.dashboardId = parseInt(this.$route.params.id as string, 10);

    this.dashboardApi.findOne(this.dashboardId, false).then((response) => {
      if (response.success) {
        this.dashboard = response.result;
      } else {
        console.log('err', response);
      }
    }).catch((err) => {
      console.log('err');
    }).finally(() => {
      store.dispatch('setLoading', false);
    });
  }

  saveChanges(): void {
    this.dashboardApi.update(this.dashboardId!, this.dashboard!).then((response) => {
      if (response.success) {
        this.$router.push(`/dashboard/${this.dashboardId}`);
      } else {
        console.log('err', response);
      }
    });
  }

  submitDelete(): void {
    this.dashboardApi.remove(this.dashboardId!).then((response) => {
      if (response.success) {
        this.$router.push('/');
      } else {
        console.log('err', response);
      }
    });
  }
}
